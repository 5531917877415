// This is a code block generated by Sydney.

// Import axios and define the base URL
import axios from "axios";
const baseURL = process.env.WEBSERVICE_URL_API;

// Create an axios instance with the base URL and some headers
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  params: {
    ClientId: process.env.BULLSEYE_CLIENT_ID,
    ApiKey: process.env.BULLSEYE_API_KEY,
  },
});

// Define a custom error class to handle axios errors
class AxiosError extends Error {
  constructor(public response: any) {
    super(response.data.message);
    this.name = "AxiosError";
  }
}

// Define a function to handle axios errors and throw a custom error
const handleError = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    throw new AxiosError(error.response);
  } else if (error.request) {
    // The request was made but no response was received
    throw new Error("No response from server");
  } else {
    // Something happened in setting up the request that triggered an Error
    throw new Error(error.message);
  }
};

// Define a function to make a GET request using axios
const get = async (url: string, params?: any) => {
  try {
    const response = await axiosInstance.get(url, { params: params });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Define a function to make a POST request using axios
const post = async (url: string, data?: any) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Define a function to make a PUT request using axios
const put = async (url: string, data?: any) => {
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Define a function to make a DELETE request using axios
const del = async (url: string) => {
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const services = {
  get,
  post,
  put,
  del,
};

// Export the functions as a default object
export default services;
