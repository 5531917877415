const bullseyeAdminURL = process.env.BULLSEYE_ADMIN_URL;

export const NewId = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};

export const FixRelativePath = function (imagePath: string | undefined | null) {
  if (!imagePath) return "";

  let returnPath = imagePath;

  if (imagePath.toLowerCase().includes("~/resources/")) {
    returnPath = imagePath
      .toLowerCase()
      .replace("~/resources/", bullseyeAdminURL + "resources/");
  }

  // I did this because sometimes the server returns encode url and if I only apply the encodeURI
  // sometimes the urls are double encode
  return encodeURI(decodeURI(returnPath));
};

export const getGEOBreadcrumbItems = function (
  countryCode: string | undefined,
  stateAbbr: string | undefined,
  city: string | undefined,
  isMemberPage: boolean = false,
  locationName: string | undefined,
  productName: string | undefined,
  locationSlug: string | undefined,
  urlParameters: string | undefined
) {
  let breadcrumbItems = [];
  let urlItem = "";
  let displayTextItem;
  const locationListBaseUrl = `${process.env.NEXT_PUBLIC_CITYLIST_URL}${process.env.NEXT_PUBLIC_INTERFACE_NAME}`;
  const locationLandingPageBaseUrl = `${process.env.NEXT_PUBLIC_BASELANDINGPAGE_URL}`;

  breadcrumbItems.push({
    url: `${process.env.NEXT_PUBLIC_STORELOCATOR_URL}${urlParameters}` || "",
    displayText: process.env.NEXT_PUBLIC_DEFAULT_LOCATOR_TEXT,
    isExternalLink: true,
    isActive: true,
  });

  if (countryCode && countryCode.length > 0) {
    displayTextItem = countryCode.toUpperCase();

    breadcrumbItems.push({
      url: `${locationListBaseUrl}${urlParameters}` || "",
      displayText: displayTextItem,
      isExternalLink: true,
      isActive: true,
    });
  }

  if (stateAbbr && stateAbbr.length > 0) {
    urlItem = `${locationListBaseUrl}/${countryCode}/${stateAbbr}${urlParameters}`;
    displayTextItem = stateAbbr.toUpperCase();

    breadcrumbItems.push({
      url: urlItem,
      displayText: displayTextItem,
      isExternalLink: city && city.length > 0 ? true : false,
      isActive: true,
    });
  }

  if (city && city.length > 0) {
    //City Level doesn't need link
    let cityName = "";
    cityName = city
      .toLowerCase()
      .split("-")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
    urlItem = `${locationListBaseUrl}/${countryCode}/${stateAbbr}/${removeDash(
      city
    )}${urlParameters}`;
    displayTextItem = `${removeDash(cityName)}`;

    breadcrumbItems.push({
      url: urlItem,
      displayText: displayTextItem,
      isExternalLink: true,
      isActive: true,
    });
  }

  if (locationName && locationName.length > 0) {
    urlItem =
      productName && productName.length > 0
        ? `${locationLandingPageBaseUrl}${countryCode}/${stateAbbr}/${city}/${locationSlug}${urlParameters}`
        : "";
    breadcrumbItems.push({
      url: urlItem,
      displayText: locationName,
      isExternalLink: false,
      isActive: productName && productName.length > 0 ? true : false,
    });
  }

  if (productName && productName.length > 0) {
    breadcrumbItems.push({
      url: urlItem,
      displayText: productName,
      isExternalLink: false,
      isActive: false,
    });
  }

  return breadcrumbItems;
};

export const getLocationIdFromSlug = (
  slug: string | string[] | undefined
): number => {
  if (!slug) return -1;

  let slugString = "";

  if (Array.isArray(slug)) {
    // The string slug
    slugString = slug[slug.length - 1];
  } else {
    slugString = slug;
  }

  const matches = slugString.match(/(\d+)$/) ?? [];

  // Check if the match exists
  if (!(matches.length > 0)) return -1;

  const locationId = matches[0];

  // Check if the extracted value is a valid number
  const isValid = !Number.isNaN(Number(locationId));

  if (!isValid) return -1;

  return Number(locationId);
};

export const removeDash = function (str: string | undefined) {
  if (!str) return "";
  return str.replace(/-/g, " ");
};
