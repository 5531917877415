export const SearchResponseLogType = {
  urlClicked: 1,
  emailClicked: 2,
  locationResultsClicked: 3,
  locationMapClicked: 4,
  directionsClicked: 5,
  promotionPrint: 6,
  promotionShare: 7,
  locationPromotionPrint: 8,
  attributeClicked: 9,
  phoneNumberClicked: 10,
  moreInfoClicked: 11,
};

export const getGoogleAnalyticEventName = (
  responseType: number,
  additionalInformation: any
) => {
  switch (responseType) {
    case SearchResponseLogType.urlClicked:
      return "Click local page website";
    case SearchResponseLogType.directionsClicked:
      return "Click local page directions";
    case SearchResponseLogType.emailClicked:
      return "Click local page email " + additionalInformation;
    case SearchResponseLogType.phoneNumberClicked:
      return "Click local page phone";
    case SearchResponseLogType.moreInfoClicked:
      return "Click more information";
    case SearchResponseLogType.attributeClicked:
      return "Click local page attribute " + additionalInformation;
    default:
      return responseType;
  }
};
