import type { AppProps } from "next/app";
import track from "react-tracking";
// import { getGoogleAnalyticEventName } from "../utils/constants";
// import { install, gtag } from "@/assets/js/googleAnalyticsHelper";
import "@/styles/globals.scss";
import { LogSearchRsltwReq } from "@/services";
import { SearchResponseLogType } from "@/utils";
import apiCalls from "@/services/httpService";

function MyApp({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />;
}

// const recordGAEvent = (data: any) => {
//   // Create a category variable by concatenating the app and eventCategory properties from the payload
//   const category = data.app + " - " + data.eventCategory;
//   // Create an action variable that either uses the eventNameGA property from the payload, or calls the
//   // getGoogleAnalyticEventName function with the responseType and eventValue properties
//   const action =
//     data.eventNameGA ??
//     getGoogleAnalyticEventName(
//       data.responseType ?? data.genericResponseType,
//       data.eventValue
//     );
//   // Create a label variable that either uses the locationName and additionalInformation
//   // properties from the payload, or just the locationName property
//   const label = data.additionalInformation
//     ? data.locationName + " " + data.additionalInformation
//     : data.locationName;

//   switch (data.event) {
//     case "pageview":
//       gtag("event", "page_view", { page_title: data.page });
//       // ReactGA.pageview(data.page);
//       break;

//     case "LocationSearchResult":
//     case "click":
//       /* console.log("event", "click", {
//         event_category: category,
//         event_action: action,
//         event_label: label,
//       }); */
//       gtag("event", "click", {
//         event_category: category,
//         event_action: action,
//         event_label: label,
//       });
//       break;
//     default:
//       break;
//   }
// };

export default track(
  { app: "Starnet-LandingPage" },
  {
    dispatch: async (data) => {
      // Sent data to the parent if the parent wants to track the events
      if (typeof window !== "undefined") {
        window?.top?.postMessage(
          {
            type: "bullseye_tracking_data",
            payload: data,
          },
          "*"
        );
      }

      if (!(data?.searchReqLogId && data?.searchReqLogId > 0)) return;

      if (
        !process.env.NEXT_PUBLIC_MEMBER_LEAD_BUTTON_CLICKS_BOTTOM ||
        !process.env.NEXT_PUBLIC_MEMBER_LEAD_BUTTON_CLICKS_TOP ||
        !process.env.NEXT_PUBLIC_MEMBER_FEATURED_PRODUCT_CLICKS ||
        !process.env.NEXT_PUBLIC_MEMBER_PHONE_NUMBER_CLICKS ||
        !process.env.NEXT_PUBLIC_PRODUCT_SPEC_SHEET_CLICKS ||
        !process.env.NEXT_PUBLIC_PRODUCT_LEAD_BUTTON_CLICKS ||
        !process.env.NEXT_PUBLIC_PRODUCT_PHONE_NUMBER_CLICKS
      )
        return;

      let attributeId = 0;
      let attributeTypeId = 0;

      let locationId: number = data.locationid;
      let responsetype: number = data.responseType;
      let searchLogId: number | null = null;
      if (data?.searchReqLogId && parseInt(data?.searchReqLogId) > 0) {
        searchLogId = parseInt(data?.searchReqLogId);
      }

      if (data.page === "Member") {
        // Attribute typeId = 3 for number type field
        attributeTypeId = 3;
        responsetype = SearchResponseLogType.attributeClicked;

        if (data.component === "leadform") {
          if (data.leadbuttondown === true) {
            //lead button is on bottom
            attributeId = parseInt(
              process.env.NEXT_PUBLIC_MEMBER_LEAD_BUTTON_CLICKS_BOTTOM
            );
          } else {
            //lead button is on top
            attributeId = parseInt(
              process.env.NEXT_PUBLIC_MEMBER_LEAD_BUTTON_CLICKS_TOP
            );
          }
          //attribute typeId = 3 for number type field
          attributeTypeId = 3;
        }

        if (data.component === "Feature Products") {
          attributeId = parseInt(
            process.env.NEXT_PUBLIC_MEMBER_FEATURED_PRODUCT_CLICKS || ""
          );
          //attribute typeId = 3 for number type field
          attributeTypeId = 3;
        }

        if (data.component === "Phone Number") {
          attributeId = parseInt(
            process.env.NEXT_PUBLIC_MEMBER_PHONE_NUMBER_CLICKS || ""
          );
        }

        if (data.component === "Url") {
          attributeId = parseInt(
            process.env.NEXT_PUBLIC_MEMBER_WEBSITE_URL_CLICKS || ""
          );
        }
      }

      if (data.page === "Product") {
        //attribute typeId = 3 for number type field
        attributeTypeId = 3;
        responsetype = SearchResponseLogType.attributeClicked;
        if (data.component === "Spec Sheet Download") {
          attributeId = parseInt(
            process.env.NEXT_PUBLIC_PRODUCT_SPEC_SHEET_CLICKS || ""
          );
        }
        if (data.component === "leadform") {
          attributeId = parseInt(
            process.env.NEXT_PUBLIC_PRODUCT_LEAD_BUTTON_CLICKS || ""
          );
        }
        if (data.component === "Phone Number") {
          attributeId = parseInt(
            process.env.NEXT_PUBLIC_PRODUCT_PHONE_NUMBER_CLICKS || ""
          );
        }

        if (data.component === "Url") {
          attributeId = parseInt(
            process.env.NEXT_PUBLIC_PRODUCT_WEBSITE_URL_CLICKS || ""
          );
        }
      }

      try {
        const responseApi = await apiCalls.get("/api/logSearchRslt", {
          locationId,
          responsetype,
          searchLogId,
          attributeId,
          attributeTypeId,
        });
      } catch (error) {
        console.log(error);
      }
    },
  }
)(MyApp);
