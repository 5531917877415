import { Attribute, CategoryKeyValue } from "@/interfaces";
import { NewId } from "./utils";

export const getAttributeValue = (
  Attributes: Attribute[],
  attributeName: string
) => {
  const attribute = Attributes.find(
    (a) => a.AttributeName.toLowerCase() === attributeName.toLowerCase()
  );
  return attribute?.AttributeValue || "";
};

export const getAttributeArray = (
  Attributes: Attribute[],
  ...attributeNames: string[]
): CategoryKeyValue[] => {
  return Attributes.filter(
    (a) =>
      attributeNames.includes(a.AttributeName.toLowerCase()) && a.AttributeValue
  ).map((attribute) => ({ id: NewId(), name: attribute.AttributeValue || "" }));
};

export const getCompanyProfileAttributes = (
  Attributes: Attribute[]
): CategoryKeyValue[] => {
  const companyProfileAttributeList = [
    "women owned small business (wosb)",
    "veteran owned small business (vosb)",
    "service-disabled veteran-owned small business (sdvosb)",
    "hub #:",
    "contractors license:",
    "contractor score:",
    "experience modification rate",
    "duns:",
    "naics:",
    "sic:",
    "gsa schedule contracts:",
    "starnet design awards winner",
    "starnet member since",
  ];

  return Attributes.filter((a) =>
    companyProfileAttributeList.includes(a.AttributeName.toLowerCase())
  )
    .filter(
      (a) =>
        a.AttributeValue &&
        (a.AttributeTypeID !== 4 || a.AttributeValue?.toLowerCase() !== "false")
    )
    .map((attribute) => {
      let attributeValue = attribute.AttributeName;
      if (attribute.AttributeTypeID === 3 || attribute.AttributeTypeID === 1) {
        attributeValue = `${attribute.AttributeName} ${attribute.AttributeValue}`;
      }
      return { id: NewId(), name: attributeValue };
    });
};
